<template>
  <div>
    <NavBar />
    <div class="contentWrapper">
      <div class="categoryNavigation"></div>
      <div class="productInfo">
        <v-carousel v-model="model" class="imageSlider" v-if="allImages.length >= 1">
          <v-carousel-item
            v-for="(image, i) in allImages"
            :key="i"
            :src="`https://api.poljopijaca.rs/` + image"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>

        <v-carousel v-model="model" class="imageSlider" v-if="allImages.length < 1">
          <v-carousel-item :src="slika" reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
        </v-carousel>

        <div class="itemDetails">
          <h1>{{ productInfo.title }}</h1>
          <div class="categoryDetailRow">
            <p>Kategorija</p>
            <p @click="() => this.$router.push(`/pretraga-oglasa/${productInfo.subCategory.id}`)">{{ productInfo.subCategory.name }}</p>
          </div>
          <div class="detailRow">
            <p>Lokacija</p>
            <p>{{ productInfo.location.length > 0 ? productInfo.location : "" }}</p>
          </div>
          <div class="detailRow">
            <p>Prodavac</p>
            <p>{{ `${productInfo.user.firstName} ${productInfo.user.lastName}` }}</p>
          </div>
          <div class="detailRow">
            <p>Cena</p>
            <p class="itemPrice" v-if="productInfo.price > 0">{{ productInfo.price }} RSD {{ productInfo.isPriceFixed ? "(fiksna)" : "" }}</p>
            <p class="itemPrice" v-if="!(productInfo.price > 0)">
              {{
                productInfo.paymentOption == 1
                  ? "DOGOVOR"
                  : productInfo.paymentOption == 2
                  ? "KONTAKT"
                  : productInfo.paymentOption == 3
                  ? "POZVATI"
                  : productInfo.paymentOption == 4
                  ? "BESPLATNO"
                  : ""
              }}
            </p>
          </div>
          <div class="detailRow">
            <p>Zamena</p>
            <p>{{ productInfo.isExchangePossible ? "Da" : "Ne" }}</p>
          </div>
          <a :href="`tel:${productInfo.phoneNumber}`">
            <button class="contactButton"><v-icon color="white">mdi-phone</v-icon>{{ productInfo.phoneNumber.length > 0 ? productInfo.phoneNumber : "NIJE UNET" }}</button>
          </a>
          <button class="reportButton" @click.prevent="reportProductDialog = true">Prijavi oglas</button>
        </div>
      </div>

      <div v-if="productInfo.description" class="itemDescription">
        <h2>Opis:</h2>
        <p>
          {{ productInfo.description }}
        </p>
      </div>
      <ReportProduct v-model="reportProductDialog" @productIsReported="showSnackbar" />
      <v-snackbar v-model="snackbar" color="green" bottom>Uspešno ste prijavili oglas</v-snackbar>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../../Components/NavBar.vue";
import ReportProduct from "./ReportProduct.vue";
import Footer from "../../Components/Footer.vue";
import slika from "../../Assets/noimg.webp";

export default {
  components: {
    NavBar,
    ReportProduct,
    Footer,
  },
  name: "BasicInfo",
  data() {
    return {
      productInfo: [],
      reportProductDialog: false,
      allImages: [],
      secondaryImages: [],
      secImg: "",
      hash: this.$route.params.hash,
      snackbar: false,
      slika: slika,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    currentLoggedUser() {
      return this.$store.getters.StateCurrentUser;
    },
  },
  methods: {
    async getProductInfo() {
      const response = await axios.get(`Product/${this.hash}`);
      this.productInfo = response.data;
      this.secImg = response.data.secondaryImgNames;
      this.secondaryImages = this.secImg.split(",");

      if (this.secImg.length >= 1) {
        this.allImages = [this.productInfo.mainImgName, ...this.secondaryImages];
      } else if (this.productInfo.mainImgName.length > 0 && this.secImg.length === 0) {
        this.allImages = [this.productInfo.mainImgName];
      } else {
        this.allImages = [];
      }
    },
    showSnackbar() {
      this.snackbar = true;

      setTimeout(() => {
        this.snackbar = false;
      }, 3000);
    },
  },
  created() {
    this.getProductInfo();
  },
};
</script>

<style lang="scss" scoped>
.contentWrapper {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  max-width: 1440px;
  margin: 2rem auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .itemDescription {
    width: 100%;
    padding: 1rem;
    text-align: justify;
  }
}

.categoryNavigation {
  display: flex;
  margin: 0.5rem 0 0 0;
  .item {
    margin: 0 0.5rem 0 0;
    cursor: pointer;
  }
  .imageSlider {
    z-index: 0;
  }
}

.categoryNavigation .item {
  margin: 0 0.5rem 0 0;
  text-decoration: underline;
}

.imageSlider {
  z-index: 0;
}

.itemDetails {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  .itemPrice {
    color: #137547;
    font-weight: bold;
  }
  a {
    .contactButton {
      width: 100%;
      margin: 0.5rem 0;
      padding: 1rem 1rem;
      background-color: #137547;
      color: #fff;
      font-weight: bold;
      border-radius: 2px;
      * {
        margin-right: 1rem;
      }
    }
  }
  .reportButton {
    width: 100%;
    margin: 0.5rem 0;
    padding: 1rem 1rem;
    background-color: #fff;
    color: #c10000;
    font-weight: bold;
    border-radius: 2px;
    border: 1px solid #c10000;
  }
  .detailRow {
    margin: 0.2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ececef;
    p {
      margin: 0;
      padding: 1rem 0.5rem;
    }
  }

  .categoryDetailRow {
    margin: 0.2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ececef;
    p {
      margin: 0;
      padding: 1rem 0.5rem;
    }

    p:nth-child(2):hover {
      cursor: pointer;
    }
  }
}

.itemDetails .itemPrice {
  color: #137547;
  font-weight: bold;
}

.contactButton {
  padding: 1rem 1rem;
  background-color: #137547;
  color: #fff;
  font-weight: bold;
  border-radius: 2px;
}

.contactButton > * {
  margin-right: 1rem;
}

.detailRow {
  margin: 0.2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ececef;
}

.detailRow p {
  margin: 0;
  padding: 1rem 0.5rem;
}

@media screen and (min-width: 1140px) {
  .contentWrapper {
    padding: 1rem;
    .productInfo {
      display: flex;
      .v-window {
        width: 70%;
      }
      .itemDetails {
        padding-top: 0;
        width: 30%;
      }
    }
    .itemDescription {
      width: 70%;
    }
  }
}
</style>
